<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <div class="flex items-center mx-1 mb-2">
      優惠產生設定
    </div>
    <vxe-form class="" ref="form" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
      <!-- <vxe-form-item title="類型" field="type" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-select v-model="data.Canceled" placeholder="">
            <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item> -->
      <vxe-form-item title="類型" field="id" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-checkbox-group v-model="data.id">
            <vxe-checkbox v-for="item in list" :key="item.value" :label="item.value" :content="item.label"></vxe-checkbox>
          </vxe-checkbox-group>
        </template>
      </vxe-form-item>
      <vxe-form-item class-name="text-right" :item-render="{}" span="24">
          <template #default>
            <vxe-button class="" type="submit" status="primary" content="確認">
            </vxe-button>
          </template>
      </vxe-form-item>
    </vxe-form>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator, onMounted } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

export default defineComponent({
  name: "SessingTable",
  components: {
  },
  props: {
    sid: Number,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const current = ref<any>();
    const list = ref<any[]>([]);
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const getRights = async() => {
      return model?.dispatch('rights/options').then((data) => {
        return data;
      }).catch((error) =>{
        console.log(error)
      })
    }

    const table = reactive({
      data: {} as any,
      isLoading: false,
      isEditing: false,
      formData: { id: [], } as any,
      selectedRow: null as any | null,
      async save() {
        if(!table.formData.id) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請選擇類型！",
          });
          return;
        }
        console.log(table.formData.id)
        await model?.dispatch('coupon/create', { list: table.formData.id, id: props.sid }).then((data) => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已產生折扣優惠列表，共${data}筆`,
          });
        }).catch((error) =>{
          console.log(error)
        })
      },
    })


    //const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const form = ref<any>();

    onMounted(async()=> {
       list.value = await getRights();
    })

    return {
      table,
      grid,
      gridOptions,
      form,
      model,
      list,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
