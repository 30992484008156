
/* eslint-disable */
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator, onMounted } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

export default defineComponent({
  name: "SessingTable",
  components: {
  },
  props: {
    sid: Number,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const current = ref<any>();
    const list = ref<any[]>([]);
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const getRights = async() => {
      return model?.dispatch('rights/options').then((data) => {
        return data;
      }).catch((error) =>{
        console.log(error)
      })
    }

    const table = reactive({
      data: {} as any,
      isLoading: false,
      isEditing: false,
      formData: { id: [], } as any,
      selectedRow: null as any | null,
      async save() {
        if(!table.formData.id) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請選擇類型！",
          });
          return;
        }
        console.log(table.formData.id)
        await model?.dispatch('coupon/create', { list: table.formData.id, id: props.sid }).then((data) => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已產生折扣優惠列表，共${data}筆`,
          });
        }).catch((error) =>{
          console.log(error)
        })
      },
    })


    //const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const form = ref<any>();

    onMounted(async()=> {
       list.value = await getRights();
    })

    return {
      table,
      grid,
      gridOptions,
      form,
      model,
      list,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
