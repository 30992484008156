
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue-demi';
import RecordTable from "@/components/coupon-set/RecordTable.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    RecordTable,
    SelectBox,
  },
  props: {
    sid: Number,
    modelValue: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model
    const grid = ref<any>({});
    const gridOptions: GridOptions = {
      title: '折扣優惠',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,
      columns: [
        { field: 'Number', title: '編號', showOverflow: true, sortable: true, resizable: false },
        { field: 'Code', title: '折扣碼', showOverflow: true, sortable: true, resizable: false },
        { field: 'Member.Person.Name', title: '會員姓名', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false },
        { field: 'Email', title: 'Email', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false },
        { field: 'StartDate', title: '開始日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'EndDate', title: '截止日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'RedeemedDate', title: '兌換日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'Status', title: '折扣狀態', showHeaderOverflow: true, showOverflow: true, sortable: true,
          formatter: ({ cellValue }) => {
            const item = model?.enums && Object.values(model?.enums.CouponStatus).find((e) => e.Value === cellValue);
            return item ? item.Name : "";
          }
        },
        {
          title: '發行',
          width: '100',
          slots: { default: "send" }, align: 'center'
        },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "460", showFooter: true },
    }


    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { disabled: 'true' } } },
        {
          field: 'Member.Name',
          title: '會員姓名',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', disabled: true }, attrs: { type: 'text' } }          
        },
        {
          field: 'Code',
          title: '折扣碼',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', disabled: 'true', placeholder: '請輸入文字' } }          
        },
        {
          field: 'Discount',
          title: '折扣',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', disabled: 'true', placeholder: '請輸入數字' } }          
        },
        {
          field: 'Email',
          title: 'Email',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', disabled: 'true', placeholder: '請輸入文字' } }          
        },
        {
          field: 'OrderId',
          title: '訂單識別碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', disabled: 'true', }, attrs: { type: 'text' } }          
        },        
        {
          field: 'StartDate',
          title: '開始日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        {
          field: 'EndDate',
          title: '截止日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        {
          field: 'RedeemedDate',
          title: '已兌換日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }          
        },             
        {
          field: 'Status',
          title: '折扣狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.CouponStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Description',
          title: '使用說明',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
      ],
      rules:{
        Name: [{ type: 'string', required: true }],
        Date: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      callback();
    }

    const deleteAll = () => {
      if(confirm("確定要清除折扣優惠列表嗎?")) {
        if(props.sid) {        
          model?.dispatch('coupon/deleteAll', props.sid).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "刪除所有折扣優惠",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }

    const send = () => {
      if(confirm("確定要發行折扣優惠嗎?")) {
        if(props.sid) {
          model?.dispatch('coupon/send', props.sid).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }

    const singleSend = (id: any) => {
      if(confirm("確定要發行折扣優惠嗎?")) {
        if(id) {
          model?.dispatch('coupon/singleSend', id).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }
    

    const getRights = async() => {
      return model?.dispatch('rights/options').then((data) => {
        return data;
      }).catch((error) =>{
        console.log(error)
      })
    }

    const table = reactive({
      data: {} as any,
      isLoading: false,
      isEditing: false,
      formData: { id: [], members: [], type: 0 } as any,
      selectedRow: null as any | null,
      async save() {
        if((!table.formData.id || table.formData.id.length) <= 0 &&
            (!table.formData.members || table.formData.members.length <= 0)) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請選擇類型或會員！",
          });
          return;
        }
        console.log(table.formData.id)
        console.log(table.formData.members)
        await model?.dispatch('coupon/create', { list: table.formData.id, id: props.sid, memberIds: table.formData.members }).then((data) => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已產生折扣優惠列表，共${data}筆`,
          });
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: `${error}`,
          });
        })
      },
    })

    const form = ref<any>();
    const list = ref<any[]>([]);
    onMounted(async()=> {
       list.value = await getRights();
       table.formData.type = 0;
    })

    watch(() => props.modelValue, (val) => {
      if(val) {
        table.formData.id = [];
        table.formData.members = [];
        table.formData.type = 0;
      }
    })

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇會員",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      columns: [
        {
          field: "Account",
          title: "帳號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Organization.Name",
          title: "公司名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Person.Department",
          title: "部門",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Person.JobTitle",
          title: "職稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/query", params) // eslint-disable-line
      }
    };

    const recordGrid = ref<any>()
    const recordModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("CouponSetId", Operator.Equal, props.sid?.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("couponLog/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('couponLog/query') : undefined,
          save: model ? (params) => model.dispatch('couponLog/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        recordModal.visible = true;
        recordModal.selectedRow = row;
        if (recordGrid.value) recordGrid.value.refresh();
      }
    })

    const onGridEdit = (row: any, callback: any) => {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    }

    const changeType = () => {
      if(table.formData.type == 0) {
        table.formData.members = [];
      } else {
        table.formData.id = [];
      }
    }

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      deleteAll,
      send,
      singleSend,
      list,
      form,
      table,
      recordGrid,
      recordModal,
      onGridEdit,
      memberSelectorOptions,
      changeType,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },    
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
