<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <div class="flex items-center mx-1 mb-2">
      優惠產生設定
    </div>
    <vxe-form class="" ref="form" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
      <vxe-form-item title="類型" field="type" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-radio-group v-model="data.type" @change="changeType">
            <vxe-radio :label="0" content="選擇會員權益"></vxe-radio>
            <vxe-radio :label="1" content="選擇會員"></vxe-radio>
          </vxe-radio-group>
        </template>
      </vxe-form-item>
      <vxe-form-item title="類型" field="id" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-checkbox-group v-model="data.id" :disabled="data.type == 1">
            <vxe-checkbox v-for="item in list" :key="item.value" :label="item.value" :content="item.label"></vxe-checkbox>
          </vxe-checkbox-group>
        </template>
      </vxe-form-item>
      <vxe-form-item title="會員選擇" field="id" :item-render="{}" span="24">
        <template #default="{ data }">
          <select-box
            class="custom-vue-selector"
            :readonly="data.type == 0"
            v-bind="memberSelectorOptions"
            v-model="data.members"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item class-name="text-left" :item-render="{}" span="24">
          <template #default>
            <vxe-button class="" type="submit" status="primary" content="確認">
            </vxe-button>
          </template>
      </vxe-form-item>
    </vxe-form>
  </fieldset>
  <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex justify-end mr-2">
            <button v-if="grid && !grid.isEditing" class="button text-white bg-green-500 shadow-md flex items-center mb-2 mr-2" @click="send">
              發行優惠
            </button>
            <button v-if="grid && !grid.isEditing" class="button text-white bg-theme-1 shadow-md flex items-center mb-2 mr-2" @click="recordModal.show">
              發行紀錄
            </button>
            <button v-if="grid && !grid.isEditing" class="button text-white bg-red-500 shadow-md flex items-center mb-2" @click="deleteAll">
              清除列表
            </button>
        </div>
  </div>
  <div class="w-full flex xl:flex-row sm:flex-col xl:justify-end">
    <!-- <button v-if="grid && !grid.isEditing" class="button text-white bg-green-500 shadow-md flex items-center mb-2 mr-2" @click="send">
      發行優惠
    </button>
    <button v-if="grid && !grid.isEditing" class="button text-white bg-theme-1 shadow-md flex items-center mb-2 mr-2" @click="recordModal.show">
      發行紀錄
    </button>
    <button v-if="grid && !grid.isEditing" class="button text-white bg-red-500 shadow-md flex items-center mb-2" @click="deleteAll">
      清除列表
    </button> -->
    <!-- <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 my-2 flex items-center" @click="grid.addNewRow">
      <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
      新增
    </button> -->
  </div>
  <Grid ref="grid" v-bind="gridOptions"
    @addNewRow="initData"
    @removeSelectedRows="hideBatchDropDown()"
    @edit="onGridEdit"
  >
    <template #send="{ row }">
      <vxe-button type="button" content="發行" @click="singleSend(row.Id)"></vxe-button>
    </template>
    <template #modal="{ row, submit, reset }">
      <vxe-form
        class="ml-3"
        ref="sessionForm"
        v-bind="formOptions"
        :data="row"
        @reset="reset"
        @submit="submit">
      </vxe-form>
    </template>
    <template #modal-footer>
      <vxe-button type="submit" status="primary" content="確認" @click="$refs.sessionForm.validate((errMap) => { if (errMap === undefined) $refs.sessionForm.dispatchEvent('submit'); })"></vxe-button>
      <vxe-button type="reset" content="重置" @click="$refs.sessionForm.dispatchEvent('reset')"></vxe-button>
    </template>
  </Grid>

  <vxe-modal v-model="recordModal.visible" width="80%" title="發行紀錄" height="650" show-zoom="true">
    <RecordTable ref="recordGrid" v-bind="recordModal.gridOptions" />
  </vxe-modal>

</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue-demi';
import RecordTable from "@/components/coupon-set/RecordTable.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    RecordTable,
    SelectBox,
  },
  props: {
    sid: Number,
    modelValue: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model
    const grid = ref<any>({});
    const gridOptions: GridOptions = {
      title: '折扣優惠',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,
      columns: [
        { field: 'Number', title: '編號', showOverflow: true, sortable: true, resizable: false },
        { field: 'Code', title: '折扣碼', showOverflow: true, sortable: true, resizable: false },
        { field: 'Member.Person.Name', title: '會員姓名', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false },
        { field: 'Email', title: 'Email', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false },
        { field: 'StartDate', title: '開始日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'EndDate', title: '截止日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'RedeemedDate', title: '兌換日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'Status', title: '折扣狀態', showHeaderOverflow: true, showOverflow: true, sortable: true,
          formatter: ({ cellValue }) => {
            const item = model?.enums && Object.values(model?.enums.CouponStatus).find((e) => e.Value === cellValue);
            return item ? item.Name : "";
          }
        },
        {
          title: '發行',
          width: '100',
          slots: { default: "send" }, align: 'center'
        },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "460", showFooter: true },
    }


    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { disabled: 'true' } } },
        {
          field: 'Member.Name',
          title: '會員姓名',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', disabled: true }, attrs: { type: 'text' } }          
        },
        {
          field: 'Code',
          title: '折扣碼',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', disabled: 'true', placeholder: '請輸入文字' } }          
        },
        {
          field: 'Discount',
          title: '折扣',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', disabled: 'true', placeholder: '請輸入數字' } }          
        },
        {
          field: 'Email',
          title: 'Email',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', disabled: 'true', placeholder: '請輸入文字' } }          
        },
        {
          field: 'OrderId',
          title: '訂單識別碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', disabled: 'true', }, attrs: { type: 'text' } }          
        },        
        {
          field: 'StartDate',
          title: '開始日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        {
          field: 'EndDate',
          title: '截止日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        {
          field: 'RedeemedDate',
          title: '已兌換日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }          
        },             
        {
          field: 'Status',
          title: '折扣狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.CouponStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Description',
          title: '使用說明',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
      ],
      rules:{
        Name: [{ type: 'string', required: true }],
        Date: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      callback();
    }

    const deleteAll = () => {
      if(confirm("確定要清除折扣優惠列表嗎?")) {
        if(props.sid) {        
          model?.dispatch('coupon/deleteAll', props.sid).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "刪除所有折扣優惠",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }

    const send = () => {
      if(confirm("確定要發行折扣優惠嗎?")) {
        if(props.sid) {
          model?.dispatch('coupon/send', props.sid).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }

    const singleSend = (id: any) => {
      if(confirm("確定要發行折扣優惠嗎?")) {
        if(id) {
          model?.dispatch('coupon/singleSend', id).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          })
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得折扣資料",
          });
        }
      }
    }
    

    const getRights = async() => {
      return model?.dispatch('rights/options').then((data) => {
        return data;
      }).catch((error) =>{
        console.log(error)
      })
    }

    const table = reactive({
      data: {} as any,
      isLoading: false,
      isEditing: false,
      formData: { id: [], members: [], type: 0 } as any,
      selectedRow: null as any | null,
      async save() {
        if((!table.formData.id || table.formData.id.length) <= 0 &&
            (!table.formData.members || table.formData.members.length <= 0)) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請選擇類型或會員！",
          });
          return;
        }
        console.log(table.formData.id)
        console.log(table.formData.members)
        await model?.dispatch('coupon/create', { list: table.formData.id, id: props.sid, memberIds: table.formData.members }).then((data) => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已產生折扣優惠列表，共${data}筆`,
          });
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: `${error}`,
          });
        })
      },
    })

    const form = ref<any>();
    const list = ref<any[]>([]);
    onMounted(async()=> {
       list.value = await getRights();
       table.formData.type = 0;
    })

    watch(() => props.modelValue, (val) => {
      if(val) {
        table.formData.id = [];
        table.formData.members = [];
        table.formData.type = 0;
      }
    })

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇會員",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      columns: [
        {
          field: "Account",
          title: "帳號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Organization.Name",
          title: "公司名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Person.Department",
          title: "部門",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Person.JobTitle",
          title: "職稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/query", params) // eslint-disable-line
      }
    };

    const recordGrid = ref<any>()
    const recordModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("CouponSetId", Operator.Equal, props.sid?.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("couponLog/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('couponLog/query') : undefined,
          save: model ? (params) => model.dispatch('couponLog/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        recordModal.visible = true;
        recordModal.selectedRow = row;
        if (recordGrid.value) recordGrid.value.refresh();
      }
    })

    const onGridEdit = (row: any, callback: any) => {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    }

    const changeType = () => {
      if(table.formData.type == 0) {
        table.formData.members = [];
      } else {
        table.formData.id = [];
      }
    }

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      deleteAll,
      send,
      singleSend,
      list,
      form,
      table,
      recordGrid,
      recordModal,
      onGridEdit,
      memberSelectorOptions,
      changeType,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },    
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
</script>

<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}
.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}
</style>
