<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">優惠管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initData"
          @edit="onGridEdit"
          @reset="(row, callback) => { callback(); row.Category = {}; }"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <!-- <template #setting="{ row }">
            <vxe-button type="button" content="設定" @click="settingModal.show(row)"></vxe-button>
          </template> -->
          <template #list="{ row }">
            <vxe-button type="button" content="查看" @click="couponModal.show(row)" :disabled="!row.Category.HasCode" ></vxe-button>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-activity="{ data }">
                <select-box v-bind="activitySelectorOptions" v-model="data.SessionId" />
              </template>
              <template #column-category="{ data }">
                <select-box v-bind="couponCategorySelectorOptions" v-model="data.CategoryId" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- <vxe-modal v-model="settingModal.visible" width="50%" height="350" title="產生設定">
      <SettingTable ref="settingGrid" v-bind="settingModal.gridOptions" :sid="settingModal.selectedRow.Id" />
    </vxe-modal> -->
    <vxe-modal v-model="couponModal.visible" width="80%" height="700" title="折扣優惠列表" show-zoom="true">
      <CouponTable ref="couponGrid" :modelValue="couponModal.visible" v-bind="couponModal.gridOptions" :sid="couponModal.selectedRow.Id" />
  </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import SettingTable from "@/components/coupon-set/SettingTable.vue";
import CouponTable from "@/components/coupon-set/CouponTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    SettingTable,
    CouponTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '優惠',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '優惠清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '優惠清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
      {
        field: 'Category.Name',
        title: '類型名稱',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: 'Session.Activity.Name', title: '活動名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: 'Session.Name', title: '場次名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: 'Discount', title: '折扣', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // { field: 'OrderId', title: '訂單識別碼', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // { field: 'Status', title: '折扣狀態', showHeaderOverflow: true, showOverflow: true, sortable: true,
      //   formatter: ({ cellValue }) => {
      //     const item = model?.enums && Object.values(model?.enums.CouponStatus).find((e) => e.Value === cellValue);
      //     return item ? item.Name : "";
      //   }
      // },
      { field: 'StartDate', title: '開始日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, 'yyyy/MM/dd') : '' },
      { field: 'EndDate', title: '截止日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, 'yyyy/MM/dd') : '' },
      // { field: 'Category.Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // {
      //   title: '設定',
      //   width: '100',
      //   slots: { default: "setting" }, align: 'center'
      // },
      {
        title: '列表設定',
        width: '100',
        slots: { default: "list" }, align: 'center'
      },
      ],
      promises: {
        query: model ? (params) => {
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('couponSet/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('couponSet/query') : undefined,
        save: model ? (params) => model.dispatch('couponSet/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 140,
      titleAlign: 'right',
      items: [
        // {
        //   field: 'Number',
        //   title: '編號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '由系統自動產生', clearable: true, disabled: 'true' } }
        // },
        // {
        //   field: 'Category.Name',
        //   title: '折扣名稱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Status',
        //   title: '折扣狀態',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.CouponStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        // {
        //   field: 'Type',
        //   title: '折扣類型',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.CouponType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        {
          field: 'CategoryId',
          title: '類型',
          span: 12,
          slots: { default: "column-category" }
        },
        {
          field: 'Discount',
          title: '折扣',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入折扣(7折=0.7)' }, }
        },
        // {
        //   field: 'Code',
        //   title: '折扣碼',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Email',
        //   title: 'Email',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'StartDate',
          title: '開始日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'EndDate',
          title: '截止日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        // {
        //   field: 'RedeemedDate',
        //   title: '已兌換日期',
        //   span: 12,
        //   // titleWidth: 80,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        // },
        // {
        //   field: 'OrderId',
        //   title: '訂單識別碼',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'MemberId',
        //   title: '會員識別碼',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Category.Ordinal',
        //   title: '排序',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        // },
        {
          field: 'SessionId',
          title: '場次資料',
          span: 24,
          slots: { default: "column-activity" }
        },        
        {
          field: 'Description', title: '使用說明', span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } },
        }
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Type: [{ required: true }],
        Discount: [{ required: true }],
        Status: [{ required: true }],
        Email: [{ required: true }],
        CategoryId: [{ required: true, message: '請選擇類型' }],
      }
    }

    const activitySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      columns: [
        { field: "Activity.Name", title: "活動名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Name", title: "場次名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("session/find", value), // eslint-disable-line
        query: (params) => {
          //if (params.condition) params.condition.and('Activity.Published', Operator.Equal, true)
          //else params.condition = new Condition('Activity.Published', Operator.Equal, true)
          return model!.dispatch("session/queryOnline", params)
        },
      },
    };

    const rightsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("rights/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true)
          else params.condition = new Condition('Published', Operator.Equal, true)
          return model!.dispatch("rights/query", params)
        },
      },
    };

    const couponCategorySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("couponCategory/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) 
            params.condition.and('Published', Operator.Equal, true);
          else params.condition = new Condition('Published', Operator.Equal, true);
          if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "Ordinal", order: 0 },{ column: "CreatedTime", order: 1 }];
          return model!.dispatch("couponCategory/query", params)
        },
      },
    };

    const initData = (row: any, callback: any) => {
      row.Published = false;
      //row.ViewCount = 0;
      //row.Status = 0;
      //row.Discount = 0;
      row.SessionId = null;
      row.Category = {
        RightsId: null,
        Name: '',
        Ordinal: 0,
        Published: false,
      }
      
      callback();
    }

    // const settingGrid = ref<any>()
    // const settingModal = reactive({
    //   visible: false,
    //   selectedRow: {} as any,
    //   gridOptions: {
    //     promises: {
    //       save: model ? (params) => model.dispatch('session/save', params) : undefined
    //     }
    //   } as GridOptions,
    //   show(row: any) {
    //     settingModal.visible = true;
    //     settingModal.selectedRow = row;
    //   }
    // })

    const couponGrid = ref<any>()
    const couponModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("CouponSetId", Operator.Equal, couponModal.selectedRow.Id.toString())
              .and(params.condition!);
            // if (!params.sortings || params.sortings?.length === 0)
            //   params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("coupon/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('coupon/query') : undefined,
          save: model ? (params) => model.dispatch('coupon/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        couponModal.visible = true;
        couponModal.selectedRow = row;
        if (couponGrid.value) couponGrid.value.refresh();
      }
    })

    return {
      grid,
      activitySelectorOptions,
      rightsSelectorOptions,
      gridOptions,
      formOptions,
      initData,
      //settingGrid,
      //settingModal,
      couponModal,
      couponGrid,
      couponCategorySelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("couponSet/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    }
  }
})
</script>
