
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import SettingTable from "@/components/coupon-set/SettingTable.vue";
import CouponTable from "@/components/coupon-set/CouponTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    SettingTable,
    CouponTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '優惠',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '優惠清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '優惠清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
      {
        field: 'Category.Name',
        title: '類型名稱',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: 'Session.Activity.Name', title: '活動名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: 'Session.Name', title: '場次名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: 'Discount', title: '折扣', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // { field: 'OrderId', title: '訂單識別碼', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // { field: 'Status', title: '折扣狀態', showHeaderOverflow: true, showOverflow: true, sortable: true,
      //   formatter: ({ cellValue }) => {
      //     const item = model?.enums && Object.values(model?.enums.CouponStatus).find((e) => e.Value === cellValue);
      //     return item ? item.Name : "";
      //   }
      // },
      { field: 'StartDate', title: '開始日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, 'yyyy/MM/dd') : '' },
      { field: 'EndDate', title: '截止日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, 'yyyy/MM/dd') : '' },
      // { field: 'Category.Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // {
      //   title: '設定',
      //   width: '100',
      //   slots: { default: "setting" }, align: 'center'
      // },
      {
        title: '列表設定',
        width: '100',
        slots: { default: "list" }, align: 'center'
      },
      ],
      promises: {
        query: model ? (params) => {
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('couponSet/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('couponSet/query') : undefined,
        save: model ? (params) => model.dispatch('couponSet/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 140,
      titleAlign: 'right',
      items: [
        // {
        //   field: 'Number',
        //   title: '編號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '由系統自動產生', clearable: true, disabled: 'true' } }
        // },
        // {
        //   field: 'Category.Name',
        //   title: '折扣名稱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Status',
        //   title: '折扣狀態',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.CouponStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        // {
        //   field: 'Type',
        //   title: '折扣類型',
        //   span: 12,
        //   itemRender: { name: "$select", options: model ? Object.values(model.enums.CouponType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        {
          field: 'CategoryId',
          title: '類型',
          span: 12,
          slots: { default: "column-category" }
        },
        {
          field: 'Discount',
          title: '折扣',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入折扣(7折=0.7)' }, }
        },
        // {
        //   field: 'Code',
        //   title: '折扣碼',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Email',
        //   title: 'Email',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'StartDate',
          title: '開始日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'EndDate',
          title: '截止日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        // {
        //   field: 'RedeemedDate',
        //   title: '已兌換日期',
        //   span: 12,
        //   // titleWidth: 80,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        // },
        // {
        //   field: 'OrderId',
        //   title: '訂單識別碼',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'MemberId',
        //   title: '會員識別碼',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Category.Ordinal',
        //   title: '排序',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        // },
        {
          field: 'SessionId',
          title: '場次資料',
          span: 24,
          slots: { default: "column-activity" }
        },        
        {
          field: 'Description', title: '使用說明', span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } },
        }
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Type: [{ required: true }],
        Discount: [{ required: true }],
        Status: [{ required: true }],
        Email: [{ required: true }],
        CategoryId: [{ required: true, message: '請選擇類型' }],
      }
    }

    const activitySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      columns: [
        { field: "Activity.Name", title: "活動名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Name", title: "場次名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("session/find", value), // eslint-disable-line
        query: (params) => {
          //if (params.condition) params.condition.and('Activity.Published', Operator.Equal, true)
          //else params.condition = new Condition('Activity.Published', Operator.Equal, true)
          return model!.dispatch("session/queryOnline", params)
        },
      },
    };

    const rightsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("rights/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true)
          else params.condition = new Condition('Published', Operator.Equal, true)
          return model!.dispatch("rights/query", params)
        },
      },
    };

    const couponCategorySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("couponCategory/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) 
            params.condition.and('Published', Operator.Equal, true);
          else params.condition = new Condition('Published', Operator.Equal, true);
          if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "Ordinal", order: 0 },{ column: "CreatedTime", order: 1 }];
          return model!.dispatch("couponCategory/query", params)
        },
      },
    };

    const initData = (row: any, callback: any) => {
      row.Published = false;
      //row.ViewCount = 0;
      //row.Status = 0;
      //row.Discount = 0;
      row.SessionId = null;
      row.Category = {
        RightsId: null,
        Name: '',
        Ordinal: 0,
        Published: false,
      }
      
      callback();
    }

    // const settingGrid = ref<any>()
    // const settingModal = reactive({
    //   visible: false,
    //   selectedRow: {} as any,
    //   gridOptions: {
    //     promises: {
    //       save: model ? (params) => model.dispatch('session/save', params) : undefined
    //     }
    //   } as GridOptions,
    //   show(row: any) {
    //     settingModal.visible = true;
    //     settingModal.selectedRow = row;
    //   }
    // })

    const couponGrid = ref<any>()
    const couponModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("CouponSetId", Operator.Equal, couponModal.selectedRow.Id.toString())
              .and(params.condition!);
            // if (!params.sortings || params.sortings?.length === 0)
            //   params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("coupon/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('coupon/query') : undefined,
          save: model ? (params) => model.dispatch('coupon/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        couponModal.visible = true;
        couponModal.selectedRow = row;
        if (couponGrid.value) couponGrid.value.refresh();
      }
    })

    return {
      grid,
      activitySelectorOptions,
      rightsSelectorOptions,
      gridOptions,
      formOptions,
      initData,
      //settingGrid,
      //settingModal,
      couponModal,
      couponGrid,
      couponCategorySelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("couponSet/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    }
  }
})
